<template>
  <a el="link" :href="href" rel="noopener" v-if="href != null">
    <slot></slot>
  </a>
  <router-link el="link" :to="to" v-else>
    <slot></slot>
  </router-link>
</template>

<script>
// import { check } from "./verify";
// const name = "link";
export default {
  props: {
    href: {
      type: String,
    },
    to: {
      type: String,
      default: "#",
    },
  },
  computed: {
    // mods() {
    //   return this.el ? `${name}-${this.el}` : name;
    // },
  },
  // watch: {
  //   el(val) {
  //     check(name, val);
  //   },
  // },
  // mounted() {
  //   check(name, this.el);
  // },
};
</script>
