<template>
  <Layout el="hm-fixed" id="home">
    <Header>
      <a href="https://favcards.com" style="width:203px;">
        <img src="../assets/logo/favcards_logo_white.svg" alt="logo" class="logo" />
      </a>
    </Header>
    <main el="main">
      <!-- <List el="xsm p-lg" id="containQR" v-if="showQR">
        <Icon name="west" style="font-size: 40px;color:white;" @click="showQR = false" />
        <QRcodeGen :code="id" baseURL="https://fav.cards/sendcard" v-if="id != null" />
      </List> -->
      <List el="xsm p-md" class="pastCards">
        <List el="column-between xsm">
          <Text el="h3" style="color:white;">Instructions</Text>
          <div el="status-success" style="--bg-color: rgb(65 169 42);" v-if="wsStatus == 'ready'">{{ wsStatus }}</div>
          <div el="status-pending" style="--bg-color: rgba(98, 0, 238, 1);" v-else-if="wsStatus == 'connecting...'">{{ wsStatus }}</div>
          <div el="status-error" style="--bg-color: rgb(227 28 61);" v-else>{{ wsStatus }}</div>
        </List>
        <Text style="color:white;margin-bottom:32px;">Have the other person scan the QR code below or tell them the code underneath the QR code.</Text>
        <QRcodeGen :code="id" baseURL="https://fav.cards/sendcard" v-if="id != null" />
        <Btn @click="joinGroup" style="margin-top:12px;" v-if="groupShareCode == null">Join Group Share</Btn>
        <Btn @click="joinGroup" style="margin-top:12px;" v-else>Group: {{groupShareCode}}</Btn>
        <Text el="h3" style="color:white;margin-top:64px;">Past Viewed Cards</Text>
        <ViewPastCards />
      </List>
    </main>
  </Layout>
</template>

<script>
// @ is an alias to /src
import QRcodeGen from "@/components/QRcodeGen.vue"
import { customAlphabet } from 'nanoid'
import router from "@/router";
import dialog from '@/components/dialog.js';
import ViewPastCards from '@/components/ViewPastCards.vue';
import Icon from "@/components/btn/Icon.vue";
// import {auth} from "@/firebase"


export default {
  name: "HomeView",
  components: {
    QRcodeGen,
    ViewPastCards,
    Icon
  },
  data(){
    return {
      id: null,
      idCounter:0,
      idLen:4,
      showQR: false,
      wsStatus: 'connecting...',
      groupShareCode:null,
    }
  },
  methods:{
    to(path){
      router.push(path);
    },
    async genID(){
      let x=this;
      const nanoid = customAlphabet('23456789abcdefghjkmnpqrstuvwxyz', x.idLen);
      this.id = nanoid() //=> "4f90d13a42"
    },
    addMinutes(d, mins) {
        return new Date(d.getTime() + mins*60000);
    },
    async joinGroup() {
       let code = await dialog.title('Group Sharing').prompt('Ask the other person for your group sharing code. Please type it in below.');
        if(code){
          this.groupShareCode = code;
          console.log('Group sharing code', code);
        }
      },
    connectToServer() {
        let x = this;
      // const ws = new WebSocket('ws://localhost:8787');
        const ws = new WebSocket('wss://favcards-websockets.burkedesigns.workers.dev');

        ws.addEventListener('message', event => {
            console.log('Message received from server', event.data);
            let msg = JSON.parse(event.data);
            if (msg.code == x.id) x.to(`/${msg.cardId}`);
            if (msg.code == x.groupShareCode) x.to(`/${msg.cardId}`);
            console.log('Message from server', msg);
        });

        ws.addEventListener('open', function (event) {
            x.wsStatus = 'ready';
            console.log('Server connection is open');
            // let msg = JSON.stringify({
            //     code: "abcd",
            //     cardId: "id_123456",
            // });
            // ws.send(msg);

            // Set a timer to automatically close the connection after 10 minutes
            setTimeout(() => {
                // if (ws.readyState === WebSocket.OPEN) {
                    console.log('Automatically disconnected after 2 minutes.');
                    x.wsStatus = 'timed out';
                    ws.close();
                // }
            }, 2 * 60 * 1000);  // 2 minutes in milliseconds
        });

        ws.addEventListener('close', function (event) {
            // Attempt to reconnect after 5 seconds
              setTimeout(() => {
                if(x.wsStatus != 'timed out'){
                  console.log('Server connection closed, attempting to reconnect...');
                  x.connectToServer();
                }
                x.wsStatus = 'disconnected';
              }, 1000);
            
        });

        ws.addEventListener('error', function (error) {
          x.wsStatus = 'error';
            console.error('WebSocket Error:', error);
        });
    }

  },
  async mounted(){
    let x = this;
    // Permutation
    // 57 characters ---- '23456789ABCDEFGHJKMNPQRSTUVWXYZ_abcdefghjkmnopqrstuvwxyz-'
    this.genID();
    this.connectToServer();
  }
};
</script>
<style>
#home{
  background-color:#0e0e0e;
}
.h-center{
  justify-content:center;
}
#containQR{
  height: 100vh;
  padding-top: 100px;
  position:sticky;
  top:0;
  background-color:#0e0e0e;
  z-index: 1;
}
.pastCards{
  max-width:calc(3.5in + 64px);
  margin:auto;
}
</style>
