<template>
  <div :el="mods">
    <slot></slot>
  </div>
</template>

<script>
import { check } from "./verify";
const name = "list";
export default {
  props: ["el"],
  computed: {
    mods() {
      return this.el != name ? `${name}-${this.el}` : name;
    },
  },
  watch: {
    el(val) {
      check(name, val);
    },
  },
  mounted() {
    check(name, this.el);
  },
};
</script>
