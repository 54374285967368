/* eslint-disable no-console */

import { register } from "register-service-worker";
import dialog from "@/components/dialog.js";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
      // dialog
      //   .title("Update Downloading")
      //   .alert(
      //     "A new update to this app is currently downloading in the background. Once complete, reopen this application."
      //   );
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      // registration.update();
      // registration.skipWaiting();
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // dialog
      //   .title("Update Installed")
      //   .alert(
      //     "A new update to this app was installed in the background. Please reload or reopen this app."
      //   );
      // window.location.reload(true);
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
