<template>
  <List el="xsm" style="text-align:center;" @click="log">
    <img :src="url" alt="qrcode" class="qr">
    <Text el="h3" class="color-w">{{code}}</Text>
  </List>
</template>
<script>
export default {
  name: "QRcodeGen",
    props:['code', 'baseURL'],
  data() {
    return {
      // baseURL:'https://fav.cards/sendcard',
    };
  },
  methods: {
    log(){
      console.log('clicked...');
    }
  },
  computed:{
    url(){
      return `https://qrcode-gen.burkedesigns.workers.dev/qr/${encodeURIComponent(`${this.baseURL}/${this.code}`)}`;
    }
  }
};
</script>
<style scoped>
.color-w{
  --color:white;
}
.qr{
  --size:400px;
  width:var(--size);
  height:var(--size);
  background-color:#fff;
  image-rendering: pixelated !important;
  margin:auto;
  /* border-radius: 12px; */
}
@media (max-width:600px){
  .qr{
    --size:300px;
  }
}
@media (max-width:400px){
  .qr{
    --size:230px;
  }
}
</style>
