import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import DesignSystem from "../design-system/vue.js";
import { createPinia } from "pinia";
import "./registerServiceWorker";

// createApp(App).use(store).use(router).mount("#app");
// initializeApp(firebaseConfig);

let app;

onAuthStateChanged(auth, (user) => {
  if (!app) {
    const pinia = createPinia();
    app = createApp(App);
    // Design System component registration
    DesignSystem.register(app);
    app.use(pinia).use(store).use(router).mount("#app");
  }

  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid;
    // router.push("/dashboard");
    store.state.main.user.displayName = user.displayName;
    store.state.main.user.email = user.email;
    store.state.main.user.photoURL = user.photoURL;

    store.commit("loadCurrentUserData");
    // console.log('test',vuex.state.main.user);
    // console.log('test',auth.currentUser);
    // console.log(user);
  } else {
    // User is signed out
    console.log("signed out...");
    store.commit("resetUser");
    // router.push("/login");
    // window.location.reload();
  }
});

// app = createApp(App);
// // Design System component registration
// DesignSystem.register(app);

// app.use(store).use(router).mount("#app");
