// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAr-aN6kzFLKrFAaZ31geZDYD8zQZSM-_M",
  authDomain: "favcards-prod.firebaseapp.com",
  projectId: "favcards-prod",
  storageBucket: "favcards-prod.appspot.com",
  messagingSenderId: "540793701942",
  appId: "1:540793701942:web:058270b71292635fa43a97",
  measurementId: "G-SCRHGMQJV8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
getAnalytics(app);
const auth = getAuth(app);
// const db = getFirestore(app);

export { auth, app };
