const register = (app) => {
  const requireComponent = require.context(
    "../design-system/components/vue",
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /[A-Z]\w+\.(vue|js)$/
  );

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName
      .split("/")
      .pop()
      .replace(/\.\w+$/, "");
    app.component(componentName, componentConfig.default || componentConfig);
  });
};

export default { register };
