<template>
  <List el="xsm" id="container">
    <!-- <div class="sticky"> -->
      <!-- <List el="sm"> -->
        <!-- <Text el="h3" style="--color:white;">Viewed Cards</Text> -->
        <!-- <List el="column xsm">
          <input type="text" placeholder="Search..." 
            :value="search.term" 
          @keypress="search.term = $event.target.value"
          @input="search.term = $event.target.value"
          id="cardSearch"
          el>
        </List> -->
      <!-- </List> -->
    <!-- </div> -->
    <TransitionGroup 
        name="list" 
        tag="div" 
        el="list-column-fill xsm" style="max-width:3.5in;">
        <div @click="$router.push(`/${item.id}`)" v-for="(item, index) in viewedCards" :key="item.id" class="cardContainer">
          <DynamicCard :card="item" type="front" />
          <!-- <a href="#" el="link" style="text-transform: uppercase;margin:auto;font-weight: bold;">Select Template</a> -->
          <!-- <div><Btn style="margin:auto;">SELECT</Btn></div> -->
        </div>
      </TransitionGroup>
      <Text v-if="viewedCards.length == 0" style="color:rgba(255,255,255,0.65);">Cards you viewed in the past will show up here!</Text>
    <div style="height:100px;"></div>
  </List>


</template>

<script setup>
// vue
import { reactive, computed, onMounted, ref } from 'vue'
// import { useStore } from 'vuex'
import router from "@/router";
// import dialog from '@/components/dialog.js';
// import API from "@/components/favcardsAPI"
// import Icon from "@/components/btn/Icon.vue";
import DynamicCard from '@/components/DynamicCard.vue';
// // gsap
import { gsap } from "gsap";

// @ is an alias to /src
// import InteractiveCard from "@/components/InteractiveCard.vue";
// import Icon from "@/components/btn/Icon.vue";

// const user = computed(() => store.getters.user);
let viewedCards = ref([]);
let search = ref({
  term:'',
})


const selectCard = (data)=>{
  // selectedTemplate.value = data;
  alert('selected');
};


const MAX_ITEMS = 50;

// Initialize the map in local storage if it doesn't exist
function initializeStorage() {
    if (!localStorage.getItem('viewedCardsMap')) {
        localStorage.setItem('viewedCardsMap', JSON.stringify([]));
    }
}

// Convert stored data to a Map object
function getViewedCardsMap() {
    const dataArr = JSON.parse(localStorage.getItem('viewedCardsMap'));
    return new Map(dataArr);
}

// Store a key-value pair in the map with a timestamp
function storeCardData(key, actualValue) {
    initializeStorage();
    const dataMap = getViewedCardsMap();

    // If the map has reached its limit, delete the oldest entry (the first one)
    if (dataMap.size >= MAX_ITEMS) {
        const oldestKey = dataMap.keys().next().value;
        dataMap.delete(oldestKey);
    }

    dataMap.set(key, {
        value: actualValue,
        timestamp: Date.now() // Current time in milliseconds
    });

    localStorage.setItem('viewedCardsMap', JSON.stringify([...dataMap]));
}

// Retrieve the actual value for a given key from the map
function retrieveCardData(key) {
    initializeStorage();
    const dataMap = getViewedCardsMap();
    const item = dataMap.get(key);
    return item ? item.value : undefined;
}

// List all actual values in the map
function listViewedCardsData() {
    initializeStorage();
    const dataMap = getViewedCardsMap();
    let result = [];
    const reversedEntries = Array.from(dataMap.entries()).reverse();
    reversedEntries.forEach(([key, item]) => {
        result.push(item.value);
    });
    return result;
}

console.log(listViewedCardsData());  // Outputs the map with all stored data

onMounted(()=>{
  viewedCards.value = listViewedCardsData();
});

</script>
<style lang="scss" scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  // transform: translateY(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
  max-width:368px;
}
@media(max-width:639px){
  .list-leave-active {
    max-width:100%;
  }
}
input{
  border-radius: 50px;
    border: 0;
    --bg-color: #fff;
    font-size: 18px;
    color: #222;
    padding: 0 24px;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
    height:45px;
    box-shadow: 0 3px 8px rgb(0, 0, 0, 0.05);
}
#container{
  width:100%;
  max-width:1200px;
  margin:auto;
}
.createCard{
  height:100%;
  width:100%;
  background-color:#222;
  border-radius: 14px;
  font-size:55px;
  color:#fff;
  // font-weight:bold;
  display:grid;
  justify-content:center;
  align-items: center;
  user-select:none;
}
// .cardList{
//   position:relative;
// }
.cardList > *{
  overflow: hidden;
  border-radius: 14px;
  user-select: none;
  aspect-ratio: 7 / 4;
  cursor: pointer;
  position:relative;
}
.cardList img{
  width:100%;
  height:100%;
  object-fit: cover;
  object-position: center;
  background-color:#fff;
}
.card .fav{
  // box-shadow: 0 3px 6px rgba(0,0,0,0.13);
  filter: drop-shadow(0 0 2px hsla(0, 0%, 0%, 0.3));
  height:35px;
  width:35px;
  color:hsla(0, 0%, 100%, 0.9);
}
.cardContainer{
  background-color: rgba(255,255,255,0.07);
    border-radius: 8px;
    padding: 0px;
    display:grid;
    grid-gap:16px;
    cursor: pointer;
}
// .card .fav:hover svg{
//   color:orange;
//   transform:scale(4) translateY(-32px) translateX(-50px);
//   z-index:3;
// }
// .card .fav svg{
//   transition:0.2s;
//   transition-timing-function: ease-in-out;
// }
.card .fav .cls-1{
  fill:currentColor;
  transition:0.2s;
}
.cardOptions{
  position: absolute;
    bottom: 0;
    padding: 12px;
    width: 100%;
    align-items: center;
    grid-template-columns: 1fr max-content;
}
.tags{
  overflow-x: auto;
  width:100%;
}
.tags > *{
  // filter: drop-shadow(0 0 2px hsla(0, 0%, 0%, 0.3));
  background-color:hsla(0, 0%, 100%, 0.7);
  // border:1px solid hsla(0, 0%, 100%, 0.65);
  box-shadow:0 0 2px hsla(0, 0%, 0%, 0.3);
  color:hsla(0, 0%, 0%, 0.65);
  font-weight:700;
  font-size: 12px;
  border-radius: 5px;
  padding: 4px 8px;
  transition:0.2s;
  white-space: nowrap;
  user-select: none;
}
.tags > *:hover{
  background-color:hsla(0, 0%, 100%, 1);
  color:#000;
}
.tagContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top:12px;
}
.tagContainer > * {
    background-color: hsla(0, 0%, 0%, 0.07);
    color: #222;
    font-weight: 500;
    font-size: 16px;
    border-radius: 5px;
    padding: 4px 8px;
    transition: 0.2s;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.tagContainer > *.active{
  background-color:hsla(0, 0%, 0%, 0.65);
  color:white;
}
.tagContainer > *:hover{
  background-color:hsla(0, 0%, 0%, 0.25);
  color:#0e0e0e;
}
.open{
  height:80px !important;
  overflow:auto;
}
.sticky {
  // position: -webkit-sticky; /* Safari */
  // position: sticky;
  // top: 0;
  z-index: 3;
  background-color: #fff;
  padding-bottom: 8px;
  box-shadow: 0 -13px 8px 26px #fff;
}
</style>
