import { createStore } from "vuex";
import router from "../router";
import API from "@/components/favcardsAPI";
let startsWith = require("lodash/startsWith");
let findKey = require("lodash/findKey");

import Cards from "./Cards";
import Groups from "./Groups";
// import Learning from "./Learning";
// import Recordings from "./Recordings";
// import Referrals from "./Referrals";

import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  //   onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
// import { reactive } from "vue";

const auth = getAuth();

const Store = {
  state: () => ({
    window: {
      width: 1366,
      timeout: null,
      loading: true,
    },
    user: {
      profileImg: null,
      displayName: null,
      firstname: null,
      lastname: null,
      email: null,
      exists: null,
      paid: false,
      photoURL: null,
      lastSignIn: null,
      createdOn: null,
      uid: null,
      stripeCustomerID: null,
      groups: [],
    },
  }),
  mutations: {
    resizeWindowEvent(state) {
      if (state.window.timeout != null) {
        clearTimeout(state.window.timeout);
      }
      state.window.timeout = setTimeout(function () {
        state.window.width = window.innerWidth;
      }, 100);
    },
    stopLoading(state) {
      state.window.loading = false;
    },
    toggleAccountDropdown(state) {
      state.layout.header.accountDropdown =
        !state.layout.header.accountDropdown;
    },
    toggleNavState(state) {
      state.layout.nav.collapse = !state.layout.nav.collapse;
      if (state.window.width < 600) {
        state.layout.section.collapse = true;
      }
    },
    collapseSection(state, val) {
      state.layout.section.collapse = val;
    },
    googleSignIn(state) {
      let x = this;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // console.log(token);
          // The signed-in user info.
          const user = result.user;
          console.log("--- Google Sign In Data ---", user);

          x.commit("loadCurrentUserData");

          // router.push("/dashboard/booking");
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          console.log(email, errorMessage);
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    },
    signIn(state, providerID) {
      let x = this;
      const auth = getAuth();
      const provider = new OAuthProvider(providerID);
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          console.log("--- Sign In Data ---", user);

          x.commit("loadCurrentUserData");

          // router.push("/dashboard/booking");
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          console.log(email, errorMessage);
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    },
    async loadCurrentUserData(state, reload = false) {
      if (auth.currentUser != null) {
        state.user.displayName = auth.currentUser.displayName;
        state.user.email = auth.currentUser.email;
        state.user.photoURL = auth.currentUser.photoURL;

        if (state.user.exists == null || reload == true) {
          const result = await API.post("/auth/user/data");
          console.log("SIGNIN DATA", result);
          if (result != null) {
            state.user = result;
            // console.log("CURRENT USER", state.user);
          } else {
            // show signup menu
            state.user.exists = false;
            console.log("No such user!");
          }
        }
      } else {
        console.log("Current user not found.");
      }
    },
    importUserData(state, data = null) {
      if (data != null) {
        state.user = data;
      } else {
        console.log("Unable to import user data.");
      }
    },
    resetUser(state) {
      state.user.profileImg = null;
      state.user.displayName = null;
      state.user.firstname = null;
      state.user.lastname = null;
      state.user.email = null;
      state.user.exists = null;
      state.user.paid = false;
      state.user.photoURL = null;
      state.user.lastSignIn = null;
      state.user.createdOn = null;
      state.user.uid = null;
      state.user.groups = [];
    },
    signOut() {
      let x = this;
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          x.commit("resetUser");
          localStorage.clear();
          router.push("/login");
        })
        .catch(() => {
          // An error happened.
        });
    },
    navTo(state, section) {
      if (state.window.width < 600) {
        state.layout.nav.collapse = !state.layout.nav.collapse;
      }
      state.layout.nav.active = section;
      router.push(state.layout.nav.menu[section].path);
      // this.layout.section.collapse = true;
    },
    activateNavPath(state) {
      let path = router.currentRoute.value.path;
      const key = findKey(state.layout.nav.menu, function (item) {
        return startsWith(path, item.path);
      });
      if (key != null) {
        state.layout.nav.active = key;
      }
    },
  },
  actions: {},
  // computed
  getters: {
    user: (state) => {
      return state.user;
    },
    // doneTodos: state => {
    //   return state.todos.filter(todo => todo.done)
    // }
  },
};

const vuex = createStore({
  modules: {
    cards: Cards,
    groups: Groups,
    // learning: Learning,
    // recordings: Recordings,
    // referrals: Referrals,
    main: Store,
  },
});

export default vuex;
