import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { auth } from "@/firebase";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/login",
  //   name: "comingsoon",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import("../views/ComingSoon.vue"),
  // },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/sendcard/:connectID",
    name: "sendcard",
    component: () => import("../views/SendCard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cards/share/:cardID",
    name: "sharecard",
    component: () => import("../views/ShareCard.vue"),
  },
  {
    path: "/exchange/contact/:cardID",
    name: "exchangecontact",
    component: () => import("../views/ExchangeContact.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "DashboardCards",
        component: () => import("../views/ViewCards.vue"),
      },
      {
        path: "account",
        component: () => import("../views/Account.vue"),
      },
      {
        path: "account/profile",
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "account/billing",
        component: () => import("../views/Billing.vue"),
      },
      {
        path: "account/billing/plans",
        component: () => import("../views/BillingPlans.vue"),
      },
      {
        path: "account/notifications",
        component: () => import("../views/Notifications.vue"),
      },
      {
        path: "account/sign-in-methods",
        component: () => import("../views/SignInMethods.vue"),
      },
      {
        path: "account/delete",
        component: () => import("../views/DangerZone.vue"),
      },
      {
        path: "account/theme",
        component: () => import("../views/Theme.vue"),
      },
      {
        path: "account/contact",
        component: () => import("../views/ContactUs.vue"),
      },
      {
        path: "cards",
        component: () => import("../views/ViewCards.vue"),
      },
      {
        path: "cards/:cardID",
        component: () => import("../views/ViewCard.vue"),
      },
      {
        path: "cards/share/:cardID",
        component: () => import("../views/ShareCard.vue"),
      },
      {
        path: "contacts",
        component: () => import("../views/ViewContacts.vue"),
      },
      {
        path: "contacts/create",
        component: () => import("../views/CreateContact.vue"),
      },
      {
        path: "contacts/view/:contactID",
        component: () => import("../views/ViewContact.vue"),
      },
      {
        path: "contacts/group/:groupID",
        component: () => import("../views/ViewContacts.vue"),
      },
      {
        path: "groups",
        component: () => import("../views/Groups.vue"),
      },
      {
        path: "groups/create",
        component: () => import("../views/CreateGroup.vue"),
      },
      {
        path: "groups/:groupID",
        component: () => import("../views/ViewGroup.vue"),
      },
      {
        path: "referrals",
        component: () => import("../views/Referrals.vue"),
      },
      {
        path: "help",
        component: () => import("../views/Help.vue"),
      },
      {
        path: "scan-business-card",
        component: () => import("../components/ScanBusinessCardCloud.vue"),
      },
      {
        path: "templates/:cardID",
        component: () => import("../views/ManageDynamicTemplates.vue"),
      },
    ],
  },
  {
    path: "/:cardID",
    name: "cards",
    component: () => import("../views/Cards.vue"),
  },
  // {
  //   path: "/card",
  //   name: "card",
  //   component: () =>
  //     import("../views/Dashboard.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default router;
