<template>

    <Transition name="d">
        <div class="dialog-container" v-if="dialog.state.active">
            <List el="md" class="dialog-inner">
                <List el="xsm">
                    <Text el="h3" v-if="dialog.state.title">{{dialog.state.title}}</Text>

                    <Text v-if="dialog.state.html" v-html="dialog.state.message"></Text>
                    <Text v-else>{{ dialog.state.message }}</Text>

                    <input v-if="dialog.state.type === 'prompt'" :type="dialog.state.inputType" v-model="userInput" @keyup.enter="dialog.ok(userInput)" placeholder="Text..." ref="input">
                </List>

                <List el="column-between xxsm">
                    <div></div>
                    <List el="column xxsm">
                        <Btn el="2ry" v-if="dialog.state.type !== 'alert'" @click="dialog.cancel()">{{dialog.state.cancelText}}</Btn>
                        <Btn @click="dialog.ok(userInput)">{{dialog.state.okText}}</Btn>
                    </List>
                </List>

            </List>
        </div>
    </Transition>
    <Transition name="dialogBG">
        <div v-if="dialog.state.active" class="dialog-bg"></div>
    </Transition>
  
</template>

<script setup>
import {ref, watch, nextTick } from 'vue'
import dialog from './dialog'
// import List from 'design-system/components/vue/List.vue';
// import Btn from 'design-system/components/vue/Btn.vue';

const userInput = ref('')
const input = ref(null)

watch(dialog.state, async (val, prevVal) => {
    if(val.type == 'prompt'){
        // alert('prompt');
        await nextTick();
        input.value.focus();
        // promptInput.target.focus();
    }
   if(!val.active){
    userInput.value = '';
   }
})
</script>

<style scoped>
.dialog-container{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  padding: 12px;
  z-index: 8000;
  overflow: auto;
  max-height: 100vh;
  display: grid;
}
.dialog-bg{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,.5);
  padding: 12px;
  z-index: 7000;
}
.dialog-inner{
  --bg-color:white;
  padding:32px;
  margin:150px auto;
  width:100%;
  max-width:450px;
  border-radius: 24px;
  box-sizing: border-box;
  box-shadow: 0 0 0 5px rgb(0 0 0 / 80%);
}
input{
  padding: 0 12px;
    border-radius: 4px;
    border: 0;
    background-color: rgba(0,0,0,0.07);
    font-size: 16px;
    height: 40px;
}

/* we will explain what these classes do next! */
.dialogBG-enter-active,
.dialogBG-leave-active {
  transition: opacity 0.2s ease;
}

.dialogBG-enter-from,
.dialogBG-leave-to {
  opacity: 0;
}

.d-enter-active,
.d-leave-active {
  transition: all 0.35s ease-in-out;
}

.d-enter-from,
.d-leave-to {
    transform: translateY(-100px);
  opacity: 0;
}
</style>