<template>
  <div el="input-list">
    <label el="input-label-error">
      <div el="sm-txt label" v-if="label !== ''">{{ label }}</div>
      <input
        :type="type"
        :placeholder="placeholder"
        el
        v-if="elTypes.input.includes(type)"
      />
      <textarea
        :placeholder="placeholder"
        el
        v-else-if="elTypes.textarea.includes(type)"
      ></textarea>
      <!-- <div el="sm-txt error error-color">Error message</div>
      <div el="sm-txt required error-color">*Required</div> -->
    </label>
    <!-- <slot></slot> -->
  </div>
  <!-- <div :el="mods">
    <slot></slot>
  </div> -->
</template>

<script>
// import { check } from "./verify";
// const name = "input";
export default {
  props: {
    type: {
      type: String,
      default: "text",
      // validator: function(value) {
      //   // The value must match one of these strings
      //   return ["text", "password", "tel"].indexOf(value) !== -1;
      // },
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      elTypes: {
        input: ["text", "password", "tel"],
        textarea: ["textarea"],
      },
    };
  },
  // computed: {
  //   mods() {
  //     return this.el ? `${name}-${this.el}` : name;
  //   },
  // },
  // watch: {
  //   el(val) {
  //     check(name, val);
  //   },
  // },
  // mounted() {
  //   check(name, this.el);
  // },
};
</script>
