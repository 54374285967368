// Define reusable mods
const shadow = ["shadow", "shadow-sm", "shadow-lg"];
const rnd = ["rnd", "rnd3", "rnd100"];
const fontWeight = ["bold", "medium", "light"];
const fontStyle = ["underline", "italic"];
const spacing = ["xlg", "lg", "md", "sm", "xsm", "xxsm"];
const padding = ["p-xlg", "p-lg", "p-md", "p-sm", "p-xsm", "p-xxsm"];
// Define all valid mods
const validMods = {
  // Core mods
  layout: ["hm", "hmf", "am", "asm", "ams", "aham", "ahams", "ham", "fixed"],
  header: [...shadow],
  list: [
    "",
    "column",
    "fill",
    "between",
    "stretch",
    "alt",
    "switch",
    "v-center",
    ...spacing,
    ...rnd,
    ...shadow,
    ...padding,
  ],
  // Component mods
  btn: ["", "p1", "p2", "2ry", "disabled", "stretch", ...rnd, ...shadow],
  text: ["p", "h1", "h2", "h3", "h4", "h5", ...fontWeight, ...fontStyle],
  modal: ["center", "left", "right"],
  alert: ["warning", "error"],
};

function check(name, el = null) {
  // check if component exists
  if (!validMods[name]) {
    console.error(`invalid component: "${name}"`);
    return;
  }
  if (el != null) {
    const vMods = validMods[name];
    const elArr = el.split(/\s(.+)/); //splits into array after first space
    const modExists = vMods.includes(elArr[0]);
    const globalModsArr = elArr[1] ? elArr[1].split(" ") : null;
    let allMods = [];
    // checks if first array is an existing mod, if not, then split array where "-" is found
    if (modExists) {
      allMods = globalModsArr ? [...globalModsArr] : null;
    } else {
      const modsArr = elArr[0].split("-");
      allMods = globalModsArr ? [...modsArr, ...globalModsArr] : [...modsArr];
    }
    if (allMods != null) {
      const diff = allMods.filter((x) => !vMods.includes(x));
      if (diff.length > 0) {
        console.error(`${name} component: invalid "${el}" attribute(s)`, diff);
      }
    }
  }
}

export { check };
