<template>
  <router-view />
  <Dialog />
</template>
<script>
import Dialog from '@/components/Dialog.vue';
export default {
  components:{
    Dialog
  },
  mounted() {
    var x = this;
    // document.body.classList.remove("loadingState");
    this.$store.commit("resizeWindowEvent");
    window.addEventListener("resize",() => x.$store.commit("resizeWindowEvent"),{ passive: true });
    // this.$store.commit('loadCurrentUserData');
  },
};
</script>
<style lang="scss">
@import "design-system/assets/scss/standard";

.logo{
  height:35px;
  width: 202.8px;
  cursor: pointer;
}
</style>
