import dialog from "@/components/dialog.js";
import { auth } from "@/firebase";

// -----------------------------------
// Public interface
// -----------------------------------

const API = {
  async post(url, body) {
    try {
      let token = "";
      if (auth.currentUser != null) {
        token = await auth.currentUser.getIdToken();
      }
      const data = await fetch(
        // `http://localhost:8787${url}`,
        `https://${process.env.VUE_APP_FAVCARDS_API}${url}`,
        {
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${token}`,
          }),
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      const res = await data.json();
      if (res.success == false) {
        await dialog.title(res.msg).alert(res.error);
      }
      return res;
    } catch (error) {
      if (url == "/auth/user/data") return null;
      console.log(url, error);
      await dialog
        .title("Unknown Error")
        .alert(
          `Please check your internet connection and try again. Error details: ${error}`
        );
    }
    // return open(res);
  },
  async postRaw(url, body) {
    let token = "";
    if (auth.currentUser != null) {
      token = await auth.currentUser.getIdToken();
    }
    const data = await fetch(
      // `http://localhost:8787${url}`,
      `https://${process.env.VUE_APP_FAVCARDS_API}${url}`,
      {
        headers: new Headers({
          Authorization: `${token}`,
        }),
        method: "POST",
        body: JSON.stringify(body),
      }
    );
    return data;
  },
  async postPagination(url, params) {
    let res = await this.post(url, params);
    return this.firebasePagination(res, url, params);
  },
  getMobileOS() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) return "Windows Phone";
    if (/android/i.test(userAgent)) return "Android";
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/(iPad|iPhone|iPod)/g.test(userAgent)) return "iOS";
    // alert(userAgent);
    return "unknown";
  },
  getUserAgent() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return userAgent;
  },
  firebasePagination(res, url, params) {
    let x = this;
    const totalRecords = res.total;
    let startAfter = res.startAfter;
    res.next = async () => {
      return await x.postPagination(url, {
        ...params,
        startAfter,
      });
    };
    res.prev = async () => {
      delete params.startAfter;
      return await x.postPagination(url, params);
    };
    res.limit = params.limit;
    return res;
  },
};

export default API;
