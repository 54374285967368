<template>
		<div class="container" :id="`container_${cardID}`">
			<div :id="cardID" :class="{card:true, interactiveCard: true, rect: card != null && card.shape == 'rect', circle: card != null && card.shape == 'circle', square: card != null && card.shape == 'square', vert: card != null && card.orientation == 'vert'}">
				<div :class="{front:true, shadow: card != null && card.front.type == 'cloudflare-image'}">
					<img src="/assets/img/default_card_front.png" alt="card front" class="cardFront" v-if="card == null" />
					<img :src="cardFrontURL" alt="card front" :class="{qr: card != null && card.front.type == 'qrcode'}" v-else-if="card.front.type != 'dynamic-template'" />
					<DynamicCardFace :card="card" :face="card.front" class="cardFront" v-else />
				</div>
				<div :class="{back:true, shadow: card != null && card.back.type == 'cloudflare-image'}" v-if="type == null">
					<img src="/assets/img/default_card_back.png" alt="card back" v-if="card == null" loading="lazy">
					<img :src="cardBackURL" alt="card back" :class="{qr: card != null && card.back.type == 'qrcode'}" loading="lazy" v-else-if="card.back.type != 'dynamic-template'" />
					<DynamicCardFace :card="card" :face="card.back" v-else />
				</div>
			</div>
		</div>
</template>
<style>
@keyframes fadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@keyframes fadeUp50 {
  from {
    opacity:0;
	transform: translateY(50%);
  }
  to {
    opacity:1;
	transform: translateY(0%);
  }
}
@keyframes fadeScale50 {
  from {
    opacity:0;
	transform: scale(0.5);
  }
  to {
    opacity:1;
	transform: scale(1);
  }
}
@keyframes fadeLoop {
  0% {
    opacity:0;
	border-radius:100px;
	transform: scale(0.6);
  }
  70% {
    opacity:1;
	border-radius:8px;
	transform: scale(1);
  }
  100% {
    opacity:0;
	transform: rotate(90deg) scale(0.5);
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>
<style scoped>
.qr{
  width:100%;
  height:100%;
  background-color:#fff;
  image-rendering: pixelated !important;
  object-fit: contain !important;
  inset:10%;
  /* border-radius: 12px; */
}
.container{
	/* filter:drop-shadow(0 5px 10px rgba(0,0,0,0.25)); */
	/* margin:auto; */
	/* max-width:3.5in; */
}
.card{
	width:100%;
	height:100%;
	/* max-width: 3.5in;
	max-height:3.5in; */
	aspect-ratio: 3.5 / 2;
	border-radius:12px;
	
	margin:auto;
	position:relative;
	backface-visibility: hidden;
	margin:auto;
	perspective: 1000px;
	user-select:none;
	opacity:0;
}
.card img,
.card .front,
.card .back{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius:12px;
}

.card .front,
.card .back{
	backface-visibility: hidden;
}
.card .shadow{
	box-shadow:0 5px 10px rgba(0,0,0,0.13);
}
.card .back{
	transform:rotateY(-90deg);
}
.circle,
.circle .front,
.circle .back,
.circle img{
	aspect-ratio: 1 / 1;
	border-radius:100%;
	object-fit: cover;
	object-position: center;
}
.square,
.square .front,
.square .back,
.square img{
	aspect-ratio: 1 / 1;
	border-radius:12px;
	object-fit: cover;
	object-position: center;
}
.rect.vert,
.rect.vert .front,
.rect.vert .back,
.rect.vert img{
	max-width:2in;
	max-height: 3.5in;
	aspect-ratio: 2 / 3.5;
	border-radius:12px;
	object-fit: cover;
	object-position: center;
}
</style>

<script setup>
import { ref, onMounted, watch, computed, nextTick, reactive   } from 'vue';
import DynamicCardFace from './DynamicCardFace.vue';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('23456789abcdefghjkmnpqrstuvwxyz', 16);

gsap.registerPlugin(Draggable, InertiaPlugin);

const props = defineProps(['card','type', 'animation']);

const card = computed(()=> props.card);

console.log('props...', props.card);

let init = ref(true);
let tl = null;
let showBack = ref(false);
let showAnimation = ref(true);
let cardID = ref(`card_${nanoid(16)}`);

const flip = () => {
	if (tl.reversed()) {
		tl.play();
	} else {
		tl.reverse();
	}
};

const cardFrontURL = computed(() => {
	let url = '/assets/img/default_card_front.png';
	if(props.card == null) return url;
	url = props.card.front.url;
	switch(props.card.front.type){
		case 'cloudflare-image':
			url = `https://imagedelivery.net/2PDExFFgSNCCUiNsQSCxIw/${url}/w=720,format=avif,metadata=none`;
			break;
		case 'qrcode':
			url = `https://qrcode-gen.burkedesigns.workers.dev/qr/${encodeURIComponent(`${card.front.url}`)}`;
			break;
	}
	if(url == ''){url = '/assets/img/default_card_front.png'}
	return url;
});
const cardBackURL = computed(() => {
	let url = '/assets/img/default_card_back.png';
	if(props.card == null) return url;
	url = props.card.back.url;
	switch(props.card.back.type){
		case 'cloudflare-image':
			url = `https://imagedelivery.net/2PDExFFgSNCCUiNsQSCxIw/${url}/w=720,format=avif,metadata=none`;
			break;
		case 'qrcode':
			url = `https://qrcode-gen.burkedesigns.workers.dev/qr/${encodeURIComponent(`${card.back.url}`)}`;
			break;
	}
	if(url == ''){url = '/assets/img/default_card_back.png'}
	return url
});

onMounted(() => {
	let x = this;
	// gsap.from('.card', {y:-200,opacity:0,rotation:180,scale:0.6, duration: 1.5,delay:0.8, ease:"sine.inOut"});
	if(props.type == null){
		let delay = 2300;
		if(showAnimation.value == true && props.animation != false){
			gsap.fromTo(`#${cardID.value}`, {y:-200,opacity:0,rotation:180,scale:0.6, ease:"sine.inOut"},
			{y:0,opacity:1,rotation:0,scale:1, duration: 1.5,delay:0.8, ease:"sine.inOut"});
			showAnimation.value = false;
		}else{
			gsap.fromTo(`#${cardID.value}`, {opacity:0, ease:"sine.inOut"},
			{y:0,opacity:1, duration: 0.8, ease:"sine.inOut"});
			showAnimation.value = false;
			delay = 0;
		}

		tl = gsap.timeline({paused:true, smoothChildTiming: true});
			tl.set(`#${cardID.value} .back`, {autoAlpha:0})
			.to(`#${cardID.value} .front`, {rotateY:90, duration: 0.3, ease:"none"})
			.set(`#${cardID.value} .front`, {autoAlpha:0})
			.set(`#${cardID.value} .back`, {autoAlpha:1})
			.fromTo(`#${cardID.value} .back`, {rotateY:-90}, {rotateY:0, duration: 0.3, ease:"none"})

		setTimeout(()=>{
			Draggable.create(`#${cardID.value}`, {
				type:"x,y", 
				edgeResistance:0.05, 
				bounds: `#container_${cardID.value}`, 
				inertia:true,
				// onClick: () => {},
				inertia:true,
				onRelease: () => {
					// gsap.to('.card', {scale:1, duration: 0.3, ease:"sine.out"});
					if(init.value === true){
						tl.play();
						init.value = false;
					}else{
						flip();
					}
				},
				onPress: () => {
					let tl2 = gsap.timeline();
					tl2.to(`#${cardID.value}`, {scale:1.1, duration: 0.3, ease:"sine.inOut"}).to(`#${cardID.value}`, {scale:1, duration: 0.3, ease:"sine.inOut"});
				},
			});
		}, delay);
	}else{
		gsap.set(`#${cardID.value}`, {opacity:1});
	}
	
});



</script>
