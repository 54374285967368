import API from "@/components/favcardsAPI";
import vuex from ".";

const Cards = {
  namespaced: true,
  state: () => ({
    list: {},
  }),
  //   Methods
  mutations: {
    async loadList(state, id) {
      // state.list = {};
      if (localStorage.cardsList != null) {
        // console.log('found list: ', localStorage.cardsList);
        vuex.commit("cards/loadStoredList", "cardsList");
      }
      const result = await API.post("/auth/cards/list");
      if (result != null) {
        state.list = result;
        vuex.commit("cards/storeList", "cardsList");
      }
    },
    delete(state, id) {
      // delete images
      delete state.list[id];
      vuex.commit("cards/storeList", "cardsList");
    },
    storeList(state, key) {
      console.log("storing card list");
      let val = JSON.stringify(state.list);
      localStorage.setItem(key, val);
    },
    loadStoredList(state, key) {
      console.log("loading cards list");
      state.list = JSON.parse(localStorage.getItem(key));
      localStorage.removeItem(key);
    },
  },
  actions: {},
  //   Computed Values
  getters: {},
};

export default Cards;
