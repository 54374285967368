<template>
  <div :el="mods" @click="toggle">
    <slot></slot>
  </div>
</template>

<script>
// import { check } from "./verify";
const name = "accordion";
export default {
  props: ["el"],
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
  },
  computed: {
    mods() {
      return `${name}${this.active ? "-active" : ""}`;
    },
  },
  // mounted() {
  //   check(name, this.el);
  // },
};
</script>
