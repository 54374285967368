import API from "@/components/favcardsAPI";
import vuex from ".";
import { auth } from "@/firebase";

const Groups = {
  namespaced: true,
  state: () => ({
    list: {},
    cards: {},
  }),
  //   Methods
  mutations: {
    async loadList(state, id) {
      if (localStorage.groupsList != null) {
        vuex.commit("groups/loadStoredList", "groupsList");
      }
      const result = await API.post("/auth/groups/list");
      if (result != null) {
        state.list = result;
        vuex.commit("groups/storeList", "groupsList");
      }
    },
    async loadGroupCards(state, id) {
      if (localStorage.groupCards != null) {
        vuex.commit("groups/loadStoredList", "groupCards");
      }
      const result = await API.post("/auth/groups/cards", { groupID: id });
      if (result != null) {
        state.cards = result;
        vuex.commit("groups/storeList", "groupCards");
      }
    },
    delete(state, id) {
      delete state.list[id];
      vuex.commit("groups/storeList", "groupsList");
    },
    deleteCard(state, id) {
      delete state.cards[id];
      vuex.commit("groups/storeList", "groupCards");
    },
    storeList(state, key) {
      let val = JSON.stringify(state.list);
      localStorage.setItem(key, val);
    },
    loadStoredList(state, key) {
      state.list = JSON.parse(localStorage.getItem(key));
      localStorage.removeItem(key);
    },
  },
  actions: {},
  //   Computed Values
  getters: {},
};

export default Groups;
