<template>
		<div class="container">
			<template v-for="item in face.content" :key="item">
				<div :src="item.src" v-if="item.type == 'div'" :style="item" />
				<img :src="item.src" alt="bg" v-else-if="item.type == 'img'" :style="item" />
				<p v-else-if="item.type == 'text'" :style="{
					...item,
					color: card.colors[item.color],
					font: card.fonts[item.font],
				}">{{ card? getInfo(item.prop) : item.value }}</p>
			</template>
		</div>
</template>
<style>
@keyframes fadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@keyframes fadeUp50 {
  from {
    opacity:0;
	transform: translateY(50%);
  }
  to {
    opacity:1;
	transform: translateY(0%);
  }
}
@keyframes fadeScale50 {
  from {
    opacity:0;
	transform: scale(0.5);
  }
  to {
    opacity:1;
	transform: scale(1);
  }
}
@keyframes fadeLoop {
  0% {
    opacity:0;
	border-radius:100px;
	transform: scale(0.6);
  }
  70% {
    opacity:1;
	border-radius:8px;
	transform: scale(1);
  }
  100% {
    opacity:0;
	transform: rotate(90deg) scale(0.5);
  }
}
</style>
<style scoped>
.container{
	width:100%;
	height:100%;
	/* max-width: 3.5in;
	max-height:2in; */
	position:relative;
	aspect-ratio: 7 / 4;
	border-radius:8px;
	/* overflow:hidden; */
	filter:drop-shadow(0 5px 10px rgba(0,0,0,0.25));
	margin:auto;
}
.container *{
	user-select:none;
}
div{
	position: absolute;
	/* width: 100%;
	height:100%; */
}
img{
	position: absolute;
	object-fit: cover;
	object-position: center;
	width: 100%;
	height:100%;
}
p{
	position: absolute;
	margin:0;
	padding:0;
	display: grid;
}
</style>

<script setup>
import { ref, onMounted, watch, computed, nextTick, reactive   } from 'vue';

const props = defineProps(['card', 'face']);

const card = computed(()=> props.card);

const getInfo = (prop)=>{
	let result = '';
	switch(prop){
		case 'fullname': 
			result = computed(()=> `${props.card.contact.firstName} ${props.card.contact.lastName}`).value;
			break;
		case 'title': 
			result = computed(()=> `${props.card.contact.title}`).value;
			break;
		default: 
			result = computed(()=> `${props.card.contact[prop]}`).value;
			break;
	}
	console.log(result);
	return result;
};

// console.log('props...', props.card);

</script>
